console.log("JS OK");


const swiper = new Swiper('.swiper-container', {
  // Optional parameters
  direction: 'horizontal',
  loop: true,
  autoplay: {
    delay: 3000,
  },
  pagination: {
        el: '.sliderNum',
        type: 'fraction',
      }
});



// UNIQUEMENT SUR HOME ET ARCHIVE
var homepage = document.querySelector(".home");
var archivepage = document.querySelector(".archive");

if ( homepage || archivepage) {

console.log("InfiniteScroll ok !");
//-------------------------------------//
// init Infinte Scroll
var listeArticle = document.querySelector('.sectionProjets');

var infScroll = new InfiniteScroll( listeArticle, {
  path: '.nextPageLink a',
  append: '.articleProjet',
  // outlayer: msnry,
  status: '.page-load-status',
  history: false,
  hideNav: '.nextPrev'
});

} //FIN IF HOME ET ARCHIVE
